import React from 'react'
import TopicPages from '../Components/topicComponent';

function topicPages(onLogout) {
  return (
    <div>
      <TopicPages />
    </div>
  )
}

export default topicPages