import React from 'react'
import InstructorviewquizComponent from '../Components/instructorviewquizComponent'
function InstructorviewquizPage() {
  return (
    <div>
<InstructorviewquizComponent/>
    </div>
  )
}

export default InstructorviewquizPage