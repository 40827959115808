import React from 'react'
import CoursesBatchesPages from '../Components/coursesbatchesComponent';

function CoursesBatches(onLogout) {
  return (
    <div>
      <CoursesBatchesPages />
    </div>
  )
}

export default CoursesBatches