import React from 'react'
import SignUp from '../Components/singupComponent'
function SignupPages() {
  return (
    <div>
<SignUp/>
    </div>
  )
}

export default SignupPages