import React from 'react'
import QuestionPages from '../Components/questionComponent';

function question(onLogout) {
  return (
    <div>
      <QuestionPages />
    </div>
  )
}

export default question