import React from 'react'
import CalenderComponent from '../Components/calenderComponent'
function CalenderPages() {
  return (
    <div>
      <CalenderComponent />
    </div>
  )
}

export default CalenderPages