import React from 'react'
import BtachesPages from '../Components/batchesComponent';

function batchesPages(onLogout) {
  return (
    <div>
      <BtachesPages />
    </div>
  )
}

export default batchesPages