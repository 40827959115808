import React from 'react'
import AddTeachersPages from '../Components/teachersaddComponent';

function addteachersPages(onLogout) {
  return (
    <div>
      <AddTeachersPages />
    </div>
  )
}

export default addteachersPages