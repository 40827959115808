import React from 'react'
import InstructorCourse from '../Components/instructorcourseComponent';
function InstructorCPage(onLogout) {
  return (
    <div>
      <InstructorCourse />
    </div>
  )
}

export default InstructorCPage