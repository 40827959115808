import React from 'react'
import CoursesStudent from '../Components/coursestudentsComponent';

function coursesStudent(onLogout) {
  return (
    <div>
      <CoursesStudent />
    </div>
  )
}

export default coursesStudent