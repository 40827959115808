import React from 'react'
import AnnouncementdetailComponent from '../Components/announcementdetailsComponent'
function AnnouncementdetailPages() {
  return (
    <div>
      <AnnouncementdetailComponent />
    </div>
  )
}

export default AnnouncementdetailPages