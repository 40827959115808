import React from 'react'
import AddQuestionPages from '../Components/addquestionsComponent.js';

function addquestion(onLogout) {
  return (
    <div>
      <AddQuestionPages />
    </div>
  )
}

export default addquestion