import React from 'react'
import CourseCategoryPages from '../Components/coursecategoryComponent';

function coursecategory(onLogout) {
  return (
    <div>
      <CourseCategoryPages />
    </div>
  )
}

export default coursecategory