import React from 'react'
import StudentsettingComponent from '../Components/studentsettingComponent'
function StudentsettingPages() {
  return (
    <div>
<StudentsettingComponent/>
    </div>
  )
}

export default  StudentsettingPages
