import React from 'react'
import UserMyProfileComponent from '../Components/usersprofileComponent'
function UserMyProfilePages() {
  return (
    <div>
<UserMyProfileComponent/>
    </div>
  )
}

export default UserMyProfilePages