import React from 'react'
import AssignmentComponent from '../Components/assignmentComponent'
function AssignmentPages() {
  return (
    <div>
      <AssignmentComponent />
    </div>
  )
}

export default AssignmentPages