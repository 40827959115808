import React from 'react'
import StutentsReportPages from '../Components/stutentsreportComponent';

function StutentsReport(onLogout) {
  return (
    <div>
      <StutentsReportPages />
    </div>
  )
}

export default StutentsReport