import React from 'react'
import QuizzePages from '../Components/quizzeComponent';

function quizzePages(onLogout) {
  return (
    <div>
      <QuizzePages />
    </div>
  )
}

export default quizzePages