import React from 'react'
import StudentwishlistComponent from '../Components/studentwishlistComponent'
function StudentwishlistPage() {
  return (
    <div>
<StudentwishlistComponent/>
    </div>
  )
}

export default  StudentwishlistPage
