import React from 'react'
import CoursesReportPages from '../Components/coursespeportComponent';

function CoursesReport(onLogout) {
  return (
    <div>
      <CoursesReportPages />
    </div>
  )
}

export default CoursesReport