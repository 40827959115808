import React from 'react'
import StudentMaterialesComponent from '../Components/studentmaterialesComponent'
function StudentMaterialesPage() {
    return (
        <div>
            <StudentMaterialesComponent />
        </div>
    )
}

export default StudentMaterialesPage