import React from 'react'
import CoursesView from '../Components/coursesviewComponent';

function coursesView(onLogout) {
  return (
    <div>
      <CoursesView />
    </div>
  )
}

export default coursesView