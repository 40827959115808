import React from 'react'
import AttemtedQuestionComponent from '../Components/attemtedquestionComponent'
function AttemtedQuestion() {
    return (
        <div>
            <AttemtedQuestionComponent />
        </div>
    )
}

export default AttemtedQuestion