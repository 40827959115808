import React from 'react'
import StudentPages from '../Components/studentComponent';

function studentPages(onLogout) {
  return (
    <div>
      <StudentPages />
    </div>
  )
}

export default studentPages