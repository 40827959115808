import React from 'react'
import QuizresultComponent from '../Components/quizresultComponent'
function QuizresultPage() {
  return (
    <div>
<QuizresultComponent/>
    </div>
  )
}

export default QuizresultPage