import React from 'react'
import QuestionCategoryPages from '../Components/questioncategoryComponent';

function questioncategory(onLogout) {
  return (
    <div>
      <QuestionCategoryPages />
    </div>
  )
}

export default questioncategory