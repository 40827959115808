import React from 'react'
import MultiplequestionComponent from '../Components/multiplequestionComponent'
function MultiplequestionPage() {
  return (
    <div>
<MultiplequestionComponent/>
    </div>
  )
}

export default MultiplequestionPage