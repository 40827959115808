import React from 'react'
import LessionPages from '../Components/lessionComponent';

function lessionPages(onLogout) {
  return (
    <div>
      <LessionPages />
    </div>
  )
}

export default lessionPages