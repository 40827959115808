import React from 'react'
import StudentquizattemptComponent from '../Components/studentquizattempComponent'
function StudentquizattemptPage() {
  return (
    <div>
<StudentquizattemptComponent/>
    </div>
  )
}

export default StudentquizattemptPage