import React from 'react'
import InstructorCourseUpdate from '../Components/instructorcourseupdateComponent';
function InstructorUpdatePage(onLogout) {
  return (
    <div>
      <InstructorCourseUpdate />
    </div>
  )
}

export default InstructorUpdatePage