import React from 'react'
import StudentaddreviewComponent from '../Components/studentaddreviewComponent';
function StudentaddreviewPages() {
  return (
    <div>
      <StudentaddreviewComponent />
    </div>
  )
}

export default StudentaddreviewPages