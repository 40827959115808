import React from 'react'
import LernerenrollcourseComponent from '../Components/lernerenrollcourseComponent'
function LernerenrollcoursePages() {
  return (
    <div>
        <LernerenrollcourseComponent/>
    </div>
  )
}

export default LernerenrollcoursePages