import React from 'react'
import StudentQuestionViewComponent from '../Components/studentquestionviewComponent'
function StudentQuestionView() {
    return (
        <div>
            <StudentQuestionViewComponent />
        </div>
    )
}

export default StudentQuestionView