import React from 'react'
import InstructoreaddquizeComponent from '../Components/instructoraddquizeComponent'
function InstructoreaddquizePage() {
  return (
    <div>
<InstructoreaddquizeComponent/>
    </div>
  )
}

export default InstructoreaddquizePage