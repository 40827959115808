import React from 'react'
import InstructorDashBoard from '../Components/instructordashboardComponent';
function InstructorDPage(onLogout) {
  return (
    <div>
      <InstructorDashBoard />
    </div>
  )
}

export default InstructorDPage