import React from 'react'
import AnnouncementComponent from '../Components/announcementComponent'
function AnnouncementPages() {
  return (
    <div>
      <AnnouncementComponent />
    </div>
  )
}

export default AnnouncementPages