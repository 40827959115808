import React from 'react'
import AssignmentdetailComponent from '../Components/assignmentdetailComponent'
function AssignmentdetailPages() {
  return (
    <div>
      <AssignmentdetailComponent />
    </div>
  )
}

export default AssignmentdetailPages