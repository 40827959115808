import React from 'react'
import CoursedetailsComponent from '../Components/coursedetailsComponent'
function coursedetailsPages() {
  return (
    <div>
         <CoursedetailsComponent/>
    </div>
  )
}

export default coursedetailsPages