import React from 'react'
import VideoPages from '../Components/videoComponent';

function videoPages(onLogout) {
  return (
    <div>
      <VideoPages />
    </div>
  )
}

export default videoPages