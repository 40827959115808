import React from 'react'
import TeachersPages from '../Components/teachersComponent';

function teachersPages(onLogout) {
  return (
    <div>
      <TeachersPages />
    </div>
  )
}

export default teachersPages