import React from 'react'
import About from '../Components/about'
function AboutPages() {
  return (
    <div>
<About />
    </div>
  )
}

export default AboutPages